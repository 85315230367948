<template>
  <div class="widget"
    :class="`
      ${doubleWidth ? 'widget--double-width' : ''}
      ${doubleHeight ? 'widget--double-height' : ''}
      ${noscroll ? 'widget--noscroll' : ''}
      ${color ? 'widget--color-' + color : ''}
      ${isLoading ? 'widget--is-loading' : ''}
      widget--${name}
    `"
  >
    <div class="widget-header">
      <h3 class="widget-title">{{ title }}</h3>
      <div class="widget-actions">
        <!-- TODO -->
        <Btn v-if="name !== 'notations'"
             class="widget-close-btn"
             grow icon="times"
             @click="$emit('widget-emits-close', name)"
             round />
      </div>
    </div>

    <div class="widget-body">
      <slot></slot>
      <Loader :active="isLoading" />
    </div>

    <div class="widget-footer" v-if="linkURL && linkText">
      <router-link :to="{ name: linkURL }" class="widget-link">
        {{ linkText }} <SvgIcon name="chevron-right"/>
      </router-link>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/base/SvgIcon.vue'
import Btn from '@/components/base/Btn.vue'
import Loader from '@/components/layout/Loader.vue'

export default {
  name: 'Widget',
  components: {
    SvgIcon,
    Btn,
    Loader,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
    },
    doubleWidth: {
      type: Boolean,
      default: false,
    },
    doubleHeight: {
      type: Boolean,
      default: false,
    },
    linkURL: {
      type: String,
    },
    linkText: {
      type: String,
    },
    noscroll: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    },
  },

  emits: ['widget-emits-close'],

  data() {
    return {
      isLoading: false,
    }
  },
}
</script>

<style lang="scss" scoped>
// WIDGET

.widget {
  display: flex;
  flex-direction: column;
  @include shadow(1);
  overflow: hidden;
  background-color: white;

  @include bp('xs') {
    border-radius: $border-radius-base;
  }
}

.widget--color-primary {
  background-color: $color-primary-lighty;
  color: white;
}

.widget--double-width {
  @include bp('xs') {
    grid-column-end: span 2;
  }
}

.widget--double-height {
  grid-row-end: span 2;
}

.widget--notations {
  > .widget-body {
    padding: $gutter-half;
  }
}

.widget-header {
  display: flex;
  gap: $gutter-half;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $color-gray-lightest;

  .widget--color-primary & {
    border-color: $color-primary-lighter;
  }
}

.widget-close-btn {
  margin-right: $gutter-eighth;
  opacity: 0.5;

  @include hocus() {
    opacity: 1;
  }
}

.widget-footer {
  border-top: 1px solid $color-gray-lightest;

  .widget--color-primary & {
    border-color: $color-primary-lighter;
  }
}

.widget-body {
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
  @include custom-scrollbar();

  .widget--noscroll &,
  .widget--is-loading & {
    overflow-y: hidden;
  }
}

.widget-title {
  padding: $gutter-half;
  padding-bottom: $gutter-quarter;
  margin-bottom: 0;
}

.widget-link {
  position: relative;
  display: block;
  padding: $gutter-half;
  padding-right: $gutter-and-half;
  text-align: right;
  font-weight: $font-weight-semibold;
  line-height: $line-height-small;

  .icon {
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    transition: all 0.25s;
  }

  @include hocus() {
    .icon {
      transform: translate3d($gutter-eighth, -50%, 0);
    }
  }

  .widget--color-primary & {
    color: mix($color-primary, white, 12.5%);

    @include hocus() {
      color: white;
    }
  }
}
</style>
