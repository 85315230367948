<template>
  <!-- MISC -->

  <h2>Dashboard</h2>

  <div class="dashboard">
    <Widget
      title="Localisations des essais"
      name="map"
      double-width
    >
      [TODO]
    </Widget>

    <Widget
      title="Calendrier des tâches"
      name="calendar"
      linkURL="home"
      linkText="Voir toutes les tâches expérimentales"
    >
      [TODO]
    </Widget>

    <Widget
      title="Notations en cours"
      name="notations"
      double-height
    >
      [TODO]
    </Widget>

    <Widget
      title="Mes protocoles"
      name="protocols"
      double-width
      linkURL="home"
      linkText="Voir tous les protocoles partagés"
    >
      [TODO]
    </Widget>

    <Widget
      title="Protocoles en partage"
      name="protocols-shared"
      double-width
      linkURL="home"
      linkText="Voir tous les protocoles partagés"
    >
      [TODO]
    </Widget>

    <Widget
      title="Essais en cours"
      name="trials"
      linkURL="home"
      linkText="Voir tous les essais en cours"
    >
      [TODO]
    </Widget>

    <Widget
      title="Utilisateurs"
      name="users"
      linkURL="home"
      linkText="Voir tous les utilisateurs"
    >
      [TODO]
    </Widget>

    <Widget
      title="Chiffres clés"
      name="summary"
    >
      [TODO]
    </Widget>
  </div>

  <hr>

  <h2>Logs and syntax highlighting</h2>

  <h3>Simple code</h3>

  <div>
    <pre><code>{{ JSON.stringify(log, null, 2) }}</code></pre>
  </div>

  <h3>JSON code</h3>

  <div v-highlight >
    <pre class="language-json"><code>{{ log }}</code></pre>
  </div>

  <h3>Javascript code</h3>

  <div v-highlight class="log">
    <pre class="language-javascript"><code><!--
-->import 'prism-es6/components/prism-markup-templating'
import 'prism-es6/components/prism-json'

export default {
  name: 'StyleguideMiscView',

  components: {
    UserName,
    UserAvatar,
    UserID,
    Alert,
    Tag,
    MiniTag,
    Btn,
    Loader,
    // VueCodeHighlight,
  },

  data() {
    return {
      log: {
        id: 4,
        date: '2021-06-21 11:30:40.37773+02',
        objet: 'Utilisateur',
        ressource_id: 2,
        ressource_secondaire_id: null,
        type: {
          id: 1,
          uid: 'CREATE',
          designation: 'Création',
        },
      },
    }
  },
}<!--
--></code></pre>
  </div>

  <hr>

  <h2>Image</h2>

  <div class="grid">
    <div class="tmp-col">
      <h3>Full size</h3>

      <div class="v-padding">
        <ImageToken
          :image="$store.state.auth.user.data.avatar"
          :alt="$store.state.auth.user.data.avatar?.nom"
          size="full"
        />
      </div>
    </div>
    <div class="tmp-col">
      <h3>Small size</h3>

      <div class="v-padding">
        <ImageToken
          :image="$store.state.auth.user.data.avatar"
          :alt="$store.state.auth.user.data.avatar?.nom"
          size="sm"
        />
      </div>
    </div>
    <div class="tmp-col">
      <h3>Medium size (default)</h3>

      <div class="v-padding">
        <ImageToken
          :image="$store.state.auth.user.data.avatar"
          :alt="$store.state.auth.user.data.avatar?.nom"
        />
      </div>
    </div>
    <div class="tmp-col">
      <h3>Large size</h3>

      <div class="v-padding">
        <ImageToken
          :image="$store.state.auth.user.data.avatar"
          :alt="$store.state.auth.user.data.avatar?.nom"
          size="lg"
        />
      </div>
    </div>
  </div>

  <hr>

  <h2>User avatars</h2>

  <div class="grid">
    <div class="tmp-col">
      <h3>Default avatar</h3>

      <h4>No image, default color</h4>

      <div class="v-padding">
        <UserAvatar initials="AB" />
      </div>

      <h4>No image, material color</h4>

      <div class="v-padding">
        <UserAvatar initials="FV" color="2" />
      </div>

      <h4>With avatar image</h4>

      <div class="v-padding">
        <UserAvatar :image="$store.state.auth.user.data.avatar" initials="MH" />
      </div>

      <h3>Big avatar</h3>

      <div class="v-padding">
        <UserAvatar
          :image="$store.state.auth.user.data.avatar"
          initials="XY"
          size="large"
        />
      </div>

      <div class="v-padding">
        <UserAvatar initials="MB" size="large" color="19" />
      </div>

      <h3>Small avatar</h3>

      <div class="v-padding">
        <UserAvatar
          :image="$store.state.auth.user.data.avatar"
          initials="XY"
          size="small"
        />
      </div>

      <div class="v-padding">
        <UserAvatar initials="PB" size="small" color="7" />
      </div>
    </div>
    <div class="tmp-col">
      <h3>User name</h3>

      <h4>Default size</h4>

      <div class="v-padding">
        <UserName firstName="Marie-Helene" lastName="Latourette" />
      </div>

      <h4>Small size</h4>

      <div class="v-padding">
        <UserName firstName="Dany" lastName="Montroy" size="small" />
      </div>

      <h4>Block style</h4>

      <div class="v-padding">
        <UserName firstName="Sandrine" lastName="Daigre" layout="block" />
      </div>

      <h4>Reverse style</h4>

      <div class="v-padding">
        <UserName firstName="Jean-Sebastien" lastName="Maury" order="reverse" />
      </div>

      <h4>Mixed style</h4>

      <div class="v-padding">
        <UserName
          firstName="Eulalie"
          lastName="Dubreuil"
          size="small"
          layout="block"
          order="reverse"
        />
      </div>
    </div>
    <div class="tmp-col">
      <h3>User ID</h3>

      <h4>Base user ID</h4>

      <div class="v-padding">
        <UserID
          userNameFirstName="Marc-Henri"
          userNameLastName="Lefebure"
          userNameSize="small"
          userNameLayout="block"
          :userAvatarImage="$store.state.auth.user.data.avatar"
          userAvatarSize="small"
        />
      </div>

      <div class="v-padding">
        <UserID
          userNameFirstName="Sandrine"
          userNameLastName="Daigre"
          userAvatarSize="small"
          userAvatarColor="11"
        />
      </div>

      <h4>Btn user ID</h4>

      <div class="v-padding">
        <Btn round grow>
          <UserID
            userNameFirstName="Madeleine"
            userNameLastName="Valette"
            userNameSize="small"
            userNameLayout="block"
            :userAvatarImage="$store.state.auth.user.data.avatar"
            userAvatarSize="small"
          />
        </Btn>
      </div>
    </div>
  </div>

  <hr />

  <h2>Modals</h2>

  <div class="v-padding">
    <Btn
      text="Default modal"
      color="primary"
      @click="modal.defaultModal = true"
    />
    &nbsp;
    <Btn
      text="Small modal"
      color="secondary"
      @click="modal.smallModal = true"
    />
    &nbsp;
    <Btn
      text="Big modal"
      color="accent"
      @click="modal.bigModal = true"
    />
    &nbsp;
    <Btn
      text="Full modal"
      color="black"
      @click="modal.fullModal = true"
    />
  </div>

  <hr />

  <h2>Alerts</h2>

  <div class="grid">
    <div class="tmp-col">
      <h3>Info alert (default)</h3>

      <div class="v-padding">
        <Alert content="<b>Lorem ipsum</b> dolor sit amet" />
      </div>
    </div>
    <div class="tmp-col">
      <h3>Info success</h3>

      <div class="v-padding">
        <Alert
          content="Lorem ipsum <a href='#TODO'>dolor sit amet</a>"
          type="success"
        />
      </div>
    </div>
    <div class="tmp-col">
      <h3>Info warning</h3>

      <div class="v-padding">
        <Alert
          content="Laudantium beatae quia <u>harum sed rerum</u> id, deleniti enim debitis!"
          type="warning"
        />
      </div>
    </div>
    <div class="tmp-col">
      <h3>Info error</h3>

      <div class="v-padding">
        <Alert
          content="Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Placeat consequuntur voluptatum cumque quod nobis quos soluta fuga excepturi,
          repudiandae ipsa laudantium beatae quia harum sed rerum id, deleniti enim debitis!"
          type="error"
        />
      </div>
    </div>

    <div class="tmp-col">
      <h3>Alerts panel (see below)</h3>

      <div class="v-padding">
        <Btn
          text="Fire error!"
          @click="
            this.emitter.emit('alert', {
              type: 'error',
              content: 'This is an error alert.',
            })
          "
        />
        <Btn
          text="Fire warning!"
          @click="
            this.emitter.emit('alert', {
              type: 'warning',
              content: 'This is an warning alert.',
            })
          "
        />
        <Btn
          text="Fire success!"
          @click="
            this.emitter.emit('alert', {
              type: 'success',
              content: 'This is an success alert.',
            })
          "
        />
        <Btn
          text="Fire info!"
          @click="
            this.emitter.emit('alert', {
              type: 'info',
              content: 'This is an info alert.',
            })
          "
        />
      </div>
    </div>
  </div>

  <hr />

  <h2>Tags</h2>

  <div class="grid">
    <div class="tmp-col">
      <h3>Tags</h3>

      <h4>Default tag</h4>

      <div class="v-padding">
        <Tag text="Lorem" />
      </div>

      <h4>Tag with icon</h4>

      <div class="v-padding">
        <Tag text="Ipsum" icon="exclamation-triangle" />
      </div>

      <h4>Tag with default action button</h4>

      <div class="v-padding">
        <Tag text="Dolor sit amet" button />
      </div>

      <h4>Tag with icon and custom action button</h4>

      <div class="v-padding">
        <Tag text="Ut" icon="info-circle" button buttonIcon="tag" />
      </div>

      <h4>Tags with colors</h4>

      <div class="v-padding">
        <div class="tags">
          <Tag text="Color primary" icon="science" button color="primary" />
          <Tag
            text="Color secondary"
            icon="calendar"
            button
            color="secondary"
          />
          <Tag
            text="Color red"
            icon="info-circle"
            button
            color="material-color-1"
          />
          <Tag
            text="Color deep purple"
            icon="exclamation-triangle"
            button
            color="material-color-4"
          />
          <Tag
            text="Color light blue"
            icon="visibility"
            button
            color="material-color-7"
          />
          <Tag
            text="Color green"
            icon="cogs"
            button
            color="material-color-10"
          />
          <Tag
            text="Color yellow"
            icon="users"
            button
            color="material-color-13"
          />
        </div>
      </div>
    </div>

    <div class="tmp-col">
      <h3>Mini tags</h3>

      <h4>Default mini tag</h4>

      <div class="v-padding">
        <MiniTag text="Consultant" />
      </div>

      <h4>Mini tags with colors</h4>

      <div class="v-padding">
        <div class="tags">
          <MiniTag text="Color primary" color="primary" />
          <MiniTag text="Color secondary" color="secondary" />
          <MiniTag text="Lorem ipsum" color="material-color-3" />
          <MiniTag text="Dolor sit amet" color="material-color-6" />
          <MiniTag text="Consectetur" color="material-color-9" />
          <MiniTag text="Adipisicing elit" color="material-color-12" />
          <MiniTag text="Similique cum inventore" color="material-color-15" />
          <MiniTag text="Obcaecati" color="material-color-19" />
          <MiniTag text="Ipsa" color="material-color-2" />
          <MiniTag
            text="Odio cumque sequi officia sit"
            color="material-color-5"
          />
        </div>
      </div>
    </div>

    <div class="tmp-col">
      <h3>SVG chips</h3>

      <h4>Default</h4>

      <div style="background-color: #f8f8f8; padding: 1.6rem;">
        <div class="v-padding">
          <SvgChip
            :image="pictogram"
            @click="onSvgChipClick"
          />
        </div>
        <div class="v-padding">
          <SvgChip
            :image="pictogram"
            @click="onSvgChipClick"
          />
        </div>
        <div class="v-padding">
          <SvgChip
            :image="pictogram"
            @click="onSvgChipClick"
          />
        </div>
        <div class="v-padding">
          <SvgChip
            :image="pictogram"
            @click="onSvgChipClick"
          />
        </div>
      </div>
    </div>
  </div>

  <hr />

  <h2>Loader</h2>

  <div class="grid">
    <div class="tmp-col">
      <h3>Animated loader</h3>

      <div class="fixed-ratio fixed-ratio--1by1" style="position: relative">
        <Loader active />
      </div>
    </div>
  </div>

  <hr />

  <h2>Notation cards</h2>

  <div class="grid">
    <div class="tmp-col">
      <NotationCard
        :reservation="{
          'date': '2022-03-01 13:14:27.322+01',
          'etat': {
            'uid': 'ENPREPARATION',
            'designation': 'En préparation'
          },
          'utilisateur': {
            'nom': 'VETTER',
            'prenom': 'Franz'
          },
          'evenement':{
            'stade':{
              'designation' : 'stade de test'
            }
          },
          'notation': {
            'designation': 'Notation fiche crée automatiquement 2',
             'progression': '88',
          },
          'essai': {
            'nom': 'Un premier essai'
          },
        }"
        isInDashboard
        isOwner
      />
    </div>
    <div class="tmp-col">
      <NotationCard
        :reservation="{
          'date': '2022-03-01 13:14:27.322+01',
          'etat': {
            'uid': 'RESERVE',
            'designation': 'Réservé'
          },
          'utilisateur': {
            'nom': 'VETTER',
            'prenom': 'Franz'
          },
          'notation': {
            'designation': 'Notation fiche crée automatiquement 2',
            'progression': '68',
          },
          'evenement':{
            'stade':{
              'designation' : 'stade de test'
            }
          },
          'essai': {
            'nom': 'Encore un autre essai avec un nom très long'
          }
        }"
        isInDashboard
        isOwner
      />
    </div>
    <div class="tmp-col">
      <NotationCard
        :reservation="{
          'date': '2022-03-01 13:14:27.322+01',
          'etat': {
            'uid': 'ENCOURS',
            'designation': 'En cours'
          },
          'utilisateur': {
            'nom': 'VETTER',
            'prenom': 'Franz'
          },
          'notation': {
            'designation': 'Notation fiche crée automatiquement 2',
            'progression': '25',
          },
          'evenement':{
            'stade':{
              'designation' : 'stade de test'
            }
          },
          'essai': {
            'nom': 'Un premier essai'
          },
        }"
        isInDashboard
        isOwner
      />
    </div>
    <div class="tmp-col">
      <NotationCard
        :reservation="{
          'date': '2022-03-01 13:14:27.322+01',
          'etat': {
            'uid': 'ATTENTESYNCHRO',
            'designation': 'En attente de synchronisation'
          },
          'utilisateur': {
            'nom': 'VETTER',
            'prenom': 'Franz'
          },
          'evenement':{
            'stade':{
              'designation' : 'stade de test'
            }
          },
          'notation': {
            'designation': 'Notation fiche crée automatiquement 2',
            'progression': '25',
          },
          'essai': {
            'nom': 'Encore un autre essai avec un nom très long'
          }
        }"
        isInDashboard
        isOwner
      />
    </div>
    <div class="tmp-col">
      <NotationCard
        :reservation="{
          'date': '2022-03-01 13:14:27.322+01',
          'etat': {
            'uid': 'ERREURSYNCHRO',
            'designation': 'Erreur de synchronisation'
          },
          'utilisateur': {
            'nom': 'VETTER',
            'prenom': 'Franz'
          },
          'notation': {
            'designation': 'Notation fiche crée automatiquement 2',
            'progression': '30',
          },
          'evenement':{
            'stade':{
              'designation' : 'stade de test'
            }
          },
          'essai': {
            'nom': 'Un premier essai'
          }
        }"
        isInDashboard
        isOwner
      />
    </div>
    <div class="tmp-col">
      <NotationCard
        :reservation="{
          'date': '2022-03-01 13:14:27.322+01',
          'etat': {
            'uid': 'PARTIELLEMENTREALISE',
            'designation': 'Partiellement réalisé'
          },
          'utilisateur': {
            'nom': 'VETTER',
            'prenom': 'Franz'
          },
          'evenement':{
            'stade':{
              'designation' : 'stade de test'
            }
          },
          'notation': {
            'designation': 'Notation fiche crée automatiquement 2',
            'progression': '55',
          },
          'essai': {
            'nom': 'Encore un autre essai avec un nom très long'
          }
        }"
        isInDashboard
        isOwner
      />
    </div>
    <div class="tmp-col">
      <NotationCard
        :reservation="{
          'date': '2022-03-01 13:14:27.322+01',

          'etat': {
            'uid': 'REALISE',
            'designation': 'Réalisé'
          },
          'utilisateur': {
            'nom': 'VETTER',
            'prenom': 'Franz'
          },
          'notation': {
            'designation': 'Notation fiche crée automatiquement 2',
            'progression': '30',
          },
          'evenement':{
            'stade':{
              'designation' : 'stade de test'
            }
          },
          'essai': {
            'nom': 'Un premier essai'
          }
        }"
        isOwner
        isInDashboard
      />
    </div>
  </div>

  <!-- MODALS -->

  <!-- Default modal-->
  <Modal
    title="Modal title"
    :active="modal.defaultModal"
    :data="{}"
    @modal-close="modal.defaultModal = false"
  >
    <template v-slot:modal-body="{}">
      <p>
        Lorem ipsum dolor sit amet
      </p>
    </template>
    <template v-slot:modal-footer="{}">
      <Btn text="Annuler" @click="modal.defaultModal = false" />
      <Btn
        text="Supprimer"
        @click="modal.defaultModal = false"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>

  <!-- Small modal-->
  <Modal
    title="Small modal title"
    :active="modal.smallModal"
    :data="{}"
    @modal-close="modal.smallModal = false"
    size="sm"
  >
    <template v-slot:modal-body="{}">
      <p>
        Lorem ipsum dolor sit amet
      </p>
    </template>
    <template v-slot:modal-footer="{}">
      <Btn text="Annuler" @click="modal.smallModal = false" />
      <Btn
        text="Supprimer"
        @click="modal.smallModal = false"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>

  <!-- Big modal-->
  <Modal
    title="Big modal title"
    :active="modal.bigModal"
    :data="{}"
    @modal-close="modal.bigModal = false"
    size="lg"
  >
    <template v-slot:modal-body="{}">
      <p>
        Lorem ipsum dolor sit amet
      </p>
    </template>
    <template v-slot:modal-footer="{}">
      <Btn text="Annuler" @click="modal.bigModal = false" />
      <Btn
        text="Supprimer"
        @click="modal.bigModal = false"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>

  <!-- Full modal-->
  <Modal
    title="Full modal title"
    :active="modal.fullModal"
    :data="{}"
    @modal-close="modal.fullModal = false"
    size="full"
  >
    <template v-slot:modal-body="{}">
      <p>
        Lorem ipsum dolor sit amet
      </p>
    </template>
    <template v-slot:modal-footer="{}">
      <Btn text="Annuler" @click="modal.fullModal = false" />
      <Btn
        text="Supprimer"
        @click="modal.fullModal = false"
        icon="trash-alt"
        color="primary"
      />
    </template>
  </Modal>

</template>

<script>
import Widget from '@/components/widget/Widget.vue'
import UserName from '@/components/user/UserName.vue'
import UserAvatar from '@/components/user/UserAvatar.vue'
import UserID from '@/components/user/UserID.vue'
import Alert from '@/components/base/Alert.vue'
import Tag from '@/components/base/Tag.vue'
import MiniTag from '@/components/base/MiniTag.vue'
import Btn from '@/components/base/Btn.vue'
import Loader from '@/components/layout/Loader.vue'
import Modal from '@/components/layout/Modal.vue'
import SvgChip from '@/components/base/SvgChip.vue'
import NotationCard from '@/components/base/NotationCard.vue'
import ImageToken from '@/components/base/ImageToken.vue'
// import { component as VueCodeHighlight } from 'vue-code-highlight'
import 'prism-es6/components/prism-markup-templating'
import 'prism-es6/components/prism-json'

export default {
  name: 'StyleguideMiscView',

  components: {
    Widget,
    ImageToken,
    UserName,
    UserAvatar,
    UserID,
    Alert,
    Tag,
    MiniTag,
    Btn,
    Loader,
    SvgChip,
    NotationCard,
    // VueCodeHighlight,
    Modal,
  },

  data() {
    return {
      log: {
        id: 4,
        date: '2021-06-21 11:30:40.37773+02',
        objet: 'Utilisateur',
        ressource_id: 2,
        // eslint-disable-next-line
        ressource: "{\"id\":2,\"nom\":\"JAEGER\",\"prenom\":\"Louis\",\"email\":\"ljaeger@diatem.net\",\"actif\":true,\"telportable\":null,\"identifiant\":\"ljaeger\",\"dat_media_id\":null,\"nom_complet\":\"Louis JAEGER\",\"couleur\":3,\"entite\":{\"id\":1,\"nom\":\"Diatem\",\"adresse\":\"2 Rue de Dublin\",\"cp\":\"67300\",\"ville\":\"Schiltigheim\",\"actif\":true,\"description\":\"Une entreprise pas comme les autres\",\"dat_media_id_1\":null,\"dat_media_id_2\":null,\"dat_media_id_3\":null,\"type\":{\"id\":1,\"uid\":\"CENTRALEACHATNATIONALE\",\"designation\":\"Centrale d'achat nationale\"},\"parent\":null,\"pays\":null},\"profils\":[{\"id\":1,\"uid\":\"SADMIN\",\"designation\":\"Super-Administrateur Diatem\",\"entite_types\":[]}]}",
        ressource_secondaire_id: null,
        type: {
          id: 1,
          uid: 'CREATE',
          designation: 'Création',
        },
      },
      pictogram: {},
      modal: {},
    }
  },

  mounted() {
    this.getPictograms()
  },

  methods: {
    onSvgChipClick() {
      console.log('SVG chip clicked!')
    },
    getPictograms() {
      this.fetchService.get('pictogramme').then((response) => {
        // eslint-disable-next-line
        this.pictogram = response.data[0]
      })
    },
  },
}
</script>
